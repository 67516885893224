import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import NavBar from "../../components/NavBar";
import axios from "axios";
import { Button, Container, Typography, TextField, MenuItem } from "@mui/material";
import divider from "../../assets/divider_01.png";
import Timeline from "@mui/lab/Timeline";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MapView from "../../components/MapView";
import sodarAura from "../../assets/contactpage/sodarchiloe_a.webp";
import { Popup } from "react-leaflet";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: "35px !important",
  },
  text2: {
    textAlign: "left",
    marginTop: theme.spacing(3),
    padding: "10px 20px",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      //fontSize: '2rem', // Ajusta el tamaño de fuente en pantallas pequeñas
      padding: "5px 10px",
    },
  },
  footer: {
    backgroundColor: "#333", // Color de fondo del footer
    color: "#fff", // Color del texto del footer
    padding: theme.spacing(2), // Espaciado interno del footer
    textAlign: "center", // Alineación del texto en el centro
    marginTop: "auto", // Coloca el footer al final de la página
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000443",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const regiones = [
  {
    value: "AP",
    label: "Región de Arica y Parinacota",
  },
  {
    value: "TA",
    label: "Región de Tarapacá",
  },
  {
    value: "AN",
    label: "Región de Antofagasta",
  },
  {
    value: "AT",
    label: "Región de Atacama",
  },
  {
    value: "CO",
    label: "Región de Coquimbo",
  },
  {
    value: "VA",
    label: "Región de Valparaíso",
  },
  {
    value: "RM",
    label: "Región Metropolitana de Santiago",
  },
  {
    value: "LI",
    label: "Región del Libertador General Bernardo O'Higgins",
  },
  {
    value: "ML",
    label: "Región del Maule",
  },
  {
    value: "NB",
    label: "Región de Ñuble",
  },
  {
    value: "BI",
    label: "Región del Biobío",
  },
  {
    value: "AR",
    label: "Región de La Araucanía",
  },
  {
    value: "LR",
    label: "Región de Los Ríos",
  },
  {
    value: "LL",
    label: "Región de Los Lagos",
  },
  {
    value: "AI",
    label: "Región de Aysén del General Carlos Ibáñez del Campo",
  },
  {
    value: "MA",
    label: "Región de Magallanes y de la Antártica Chilena",
  },
  {
    value: "EX",
    label: "Extranjero",
  },
];
const tipo = [
  {
    value: "Eolico",
    label: "Eolico",
  },
  {
    value: "Solar",
    label: "Solar",
  },
  {
    value: "Eolico y Solar",
    label: "Eolico y Solar",
  },
  {
    value: "Otro",
    label: "Otro",
  },
];
export default function ExperienciaTest() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [filtroProyecto, setFiltroProyecto] = useState({
    tipo: "",
    region: "",
  });
  const history = useNavigate()

  /*const axiosClient = axios.create({
    baseURL:'http://localhost:4000'
  })*/

  const axiosClient = axios.create({
    baseURL:'https://desamd.cl:4000'
  })

  const areRequiredFieldsFilled = () => {

    const requiredFields = ["tipo","region"];
    
    if(!filtroProyecto.tipo || !filtroProyecto.region){
        return false
    }
    
    return true; // Si todos los campos requeridos están llenos, devuelve true
  };
  const handleFilterDetails = (tipo, region) =>{
    console.log(tipo, region)
    history(`/getproyectosregion/${tipo}/${region}`)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== "") {
      setFiltroProyecto((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  console.log(filtroProyecto)
  return (
    <div>
      <Container sx={{ marginTop: 2 }} maxWidth="xl">
        <NavBar />
      </Container>
      <br></br>
      <br></br>
      <div className="image-container">
        <LazyLoadImage
          alt="Sodar Aura del Sur"
          height={"auto"}
          width={"100%"}
          src={sodarAura}
        />
        <div className="image-overlay">
          <Typography
            variant="h2"
            className={classes.text2}
            sx={{
              fontSize: isSmallScreen ? "1.5rem" : "3rem",
              fontFamily: "Open Sans, sans-serif",
            }}
          >
            MÁS DE 15 AÑOS DE EXPERIENCIA EN PROYECTOS DE PROSPECCIÓN EÓLICA Y
            SOLAR
          </Typography>
        </div>
      </div>

      <div style={{ width: "100px", height: "90px" }}>
        <Box display="flex" alignItems="center" height="100%">
          <Box
            sx={{
              height: "100%",
              width: "4px", // Ajusta el ancho de la línea aquí
              backgroundColor: "black", // Ajusta el color de la línea aquí
              marginRight: "8px", // Espacio entre la línea y el texto
            }}
          ></Box>
        </Box>
        <Typography
          variant="h5"
          className={classes.text}
          sx={{
            fontWeight: "bold",
            fontFamily: "Open Sans, sans-serif",
            marginLeft: isSmallScreen ? "50px" : "150px",
          }}
        >
          PROYECTOS
        </Typography>
      </div>
      <Typography
        variant="subtitle1"
        sx={{
          fontFamily: "Open Sans, sans-serif",
          marginLeft: isSmallScreen ? "50px" : "150px",
          marginBottom: "50px",
        }}
      >
        NUESTROS PROYECTOS A LO LARGO DE CHILE
      </Typography>
      <div>
        <MapView />
      </div>
      <div>
        <Typography
          variant="h5"
          className={classes.text}
          sx={{ fontFamily: "Open Sans, sans-serif", textAlign: "center", marginBottom:'90px'}}
        >
          BUSCA UN PROYECTO
        </Typography>
      </div>
      <div style={{marginLeft:'50px', marginRight:'50px', marginTop:'20px'}}>
        <Grid container spacing={3} >
          <Grid xs={12} sm={5} item>
            <TextField
              name="tipo"
              label= "TIPO DE PROYECTO"
              fullWidth
              select
              required
              variant="standard"
              margin="normal"
              onChange={handleChange}
            >
              {tipo.map((t) =>(
                <MenuItem key={t.value} value={t.value}>
                  {t.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs={12} sm={5} item>
            <TextField
              name='region'
              label='REGIÓN'
              fullWidth
              select
              required
              variant="standard"
              margin="normal"
              onChange={handleChange}
            >
              {regiones.map((r) =>(
                <MenuItem key={r.value} value={r.value}>
                  {r.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs={12} sm={2} item>
            <div style={{display:'flex', justifyContent:'center'}}>
              
              <IconButton 
                aria-label="buscar"
                size="large" 
                type="submit"
                style={{backgroundColor:'#000443', color: 'white', marginTop:isSmallScreen ? "10px" : "25px"}}
                onClick={() => handleFilterDetails(filtroProyecto.tipo, filtroProyecto.region)}
                disabled={!areRequiredFieldsFilled()}
              >
                <SearchIcon/>
              </IconButton>
              
            </div>
          </Grid>
        </Grid>
      </div>
      <div align="center" style={{ marginTop: "100px", marginBottom: "15px" }}>
        <img src={divider} />
      </div>
      <footer className={classes.footer}>
        <Typography
          variant="body2"
          sx={{ fontFamily: "Open Sans, sans-serif" }}
        >
          © 2024 Desamd. Todos los derechos reservados.
        </Typography>
      </footer>
    </div>
  );
}
