import React, {useState, useEffect} from "react";
import { Grid, makeStyles } from "@material-ui/core";
import NavBarAdm from "../../components/NavBarAdm";
import { Container, Typography, Box, TextField, Button, MenuItem, CircularProgress } from "@mui/material";
import axios from 'axios';
import swal from 'sweetalert'
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
    }


}))


export default function AgregarClientes(){
    const classes = useStyles()
    const [data, setData]  = useState([])
    const [clientes, setCliente] = useState([])
    const [regClnt, setRegClnt] = useState({
        nombre: '',
        tipo:'',
        padre:'',
        contacto:'',
        correo:'',
        dominio:''
        
    })
    const [loading, setLoading] = useState(false);

    const tipos = [
        {
            value: 'proveedor',
            label: 'Proveedor',
        },
        {
            value: 'cliente',
            label: 'Cliente',
        },
        {
            value: 'proveedor/cliente',
            label: 'Proveedor y Cliente'
        }
    ]
    const handleChange = e => {
        const {name,value} = e.target;
        if (name !== ''){
            setRegClnt(prevState => ({
                ...prevState,
                [name]:value
            }))
        }
    }
    
    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/

    const axiosClient = axios.create({
        baseURL:'https://desamd.cl:4000'
    })

    const getClientes = async()=>{
        await axiosClient.get('/api/clientes/getClientes')
        .then(response=>{
            console.log(response.data)
            setCliente(response.data)
        })
    }

    useEffect(()=>{
        getClientes()
    },[])
    const registroCliente = async () => {
        console.log(regClnt)
        setLoading(true)
        await axiosClient.post('/api/clientes/addCliente',regClnt)
        .then(response => {
            console.log(response.status)
            setLoading(false)
            if(response.status === 200){

                mostrarAlertaRegistroCorrecto()
                setTimeout(() =>  {window.location.reload(false)}, 2000);
            }
        })
        .catch(error =>{
            console.log(error.request.status)
            
            if (error.request.status === 406){
                console.log("Usuario ya registrado")
                mostrarAlertaClienteRegistrado();
                setTimeout(() =>  {window.location.reload(false)}, 2000);
                //mostrarAlertaUsuarioRegistrado()
            }
            else{
                mostrarServerError()
            }

        })
    }
    const areRequiredFieldsFilled = () => {
        const requiredFields = ["nombre"];
        
        for (const field of requiredFields) {
          if (!regClnt[field]) {
            return false; // Si falta algún campo requerido, devuelve false
          }
        }
        
        return true; // Si todos los campos requeridos están llenos, devuelve true
    };


    const mostrarAlertaRegistroCorrecto = () =>{
        swal({
            title:'Registrado correctamente!',
            icon:'success'
        })
    }

    const mostrarAlertaClienteRegistrado = () =>{
        swal({
            title:'Cliente ya registrado',
            text:'El id del cliente ya estaba en nuestra base de datos',
            icon:'warning'
        })
    }

    const mostrarServerError = () => {
        swal({
            title: 'SERVER ERROR',
            icon: 'error'
        })
    }

    const ids =  clientes.map(item => item)
    return(
        <div>
            <Container sx = {{marginTop:8}} maxWidth = 'xl'>
                <NavBarAdm/>
                <h1 className = { classes.text3 }>
                    <Typography variant="h4" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mb={6}>
                        Agregar Cliente
                    </Typography>   
                </h1>
                <div align = 'center'>
                    
                    <div>
                        <Box
                            marginRight={'5%'}
                            marginLeft={'5%'}
                        >
                            <Grid container>
                                <Grid xs={12} sm={4} style={{ textAlign: 'left' }}>
                                    <Typography
                                    variant="h5" 
                                    mt={3} 
                                    sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        Cliente: 
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={8}>
                                    <TextField
                                        name="nombre"
                                        required
                                        id="outlined-required"
                                        label="Nombre Cliente"
                                        fullWidth
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid xs={12} sm={4} style={{ textAlign: 'left' }}>
                                    <Typography
                                        variant="h5" 
                                        mt={3} 
                                        sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        Tipo:
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={8}>
                                    <TextField
                                        name="tipo"
                                        required
                                        id="outlined-required-select-currency"
                                        select
                                        label="Tipo Cliente"
                                        fullWidth
                                        margin="normal"
                                        onChange={handleChange}
                                    >
                                        {tipos.map((tipo) => (
                                            <MenuItem key={tipo} value={tipo.value}>
                                                {tipo.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid xs={12} sm={4} style={{ textAlign: 'left' }}>
                                    <Typography
                                        variant="h5" 
                                        mt={3} 
                                        sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        Padre:
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={8}>
                                    <TextField
                                        name="padre"
                                        required
                                        id="outlined-required-select-currency"
                                        select
                                        label="Padre"
                                        fullWidth
                                        margin="normal"
                                        onChange={handleChange}
                                    >
                                        {ids.map((id) => (
                                            <MenuItem key={id} value={id.id}>
                                                {id.nombre}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid xs={12} sm={4} style={{ textAlign: 'left' }}>
                                    <Typography
                                    variant="h5" 
                                    mt={3} 
                                    sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        Contacto: 
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={8}>
                                    <TextField
                                        name="contacto"
                                        required
                                        id="outlined-required"
                                        label="Contacto"
                                        fullWidth
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid xs={12} sm={4} style={{ textAlign: 'left' }}>
                                    <Typography
                                    variant="h5" 
                                    mt={3} 
                                    sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        Correo: 
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={8}>
                                    <TextField
                                        name="correo"
                                        required
                                        id="outlined-required"
                                        label="Correo"
                                        fullWidth
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid xs={12} sm={4} style={{ textAlign: 'left' }}>
                                    <Typography
                                    variant="h5" 
                                    mt={3} 
                                    sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        Dominio: 
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sm={8}>
                                    <TextField
                                        name="dominio"
                                        required
                                        id="outlined-required"
                                        label="Dominio"
                                        fullWidth
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <br></br>
                        <Box
                            marginTop={5}
                            marginLeft={'30%'}
                            marginRight={'30%'}
                            
                        >
                            <LoadingButton 
                                className={classes.button}
                                
                                type = "button"
                                variant = 'contained'
                                marginTop = {10}
                                size="large"
                                fullWidth
                                onClick={() => registroCliente()}
                                sx = {{fontFamily: 'Open Sans, sans-serif'}}
                                disabled={!areRequiredFieldsFilled()}
                            >
                                {loading ? <CircularProgress size={24}/>: "Guardar"}
                            </LoadingButton >
                        </Box>
                        <br></br>
                        <br></br>
                    </div>
                
                </div>
            </Container>
            
        </div>
    );
}