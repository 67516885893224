import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import NavBarAdm from "../../components/NavBarAdm";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Switch,
  Stack,
} from "@mui/material";
import axios from "axios";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100v",
  },
  container: {
    opacity: "0.8",
    height: "80%", //largo del contenedor
    marginTop: theme.spacing(10), //altura del contenedor
    [theme.breakpoints.down(400 + theme.spacing(4) + 4)]: {
      //responsive
      marginTop: 0,
      width: "100%",
      height: "100%",
    },
  },
  div: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(6),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2), //mrgen general
  },

  text: {
    marginLeft: 155,
    marginTop: 30,
  },
  text2: {
    marginLeft: 128,
    marginTop: 25,
  },
  text3: {
    textAlign: "center",
    marginTop: theme.spacing(15),
  },
}));

const regiones = [
  {
    value: "AP",
    label: "Región de Arica y Parinacota",
  },
  {
    value: "TA",
    label: "Región de Tarapacá",
  },
  {
    value: "AN",
    label: "Región de Antofagasta",
  },
  {
    value: "AT",
    label: "Región de Atacama",
  },
  {
    value: "CO",
    label: "Región de Coquimbo",
  },
  {
    value: "VA",
    label: "Región de Valparaíso",
  },
  {
    value: "RM",
    label: "Región Metropolitana de Santiago",
  },
  {
    value: "LI",
    label: "Región del Libertador General Bernardo O'Higgins",
  },
  {
    value: "ML",
    label: "Región del Maule",
  },
  {
    value: "NB",
    label: "Región de Ñuble",
  },
  {
    value: "BI",
    label: "Región del Biobío",
  },
  {
    value: "AR",
    label: "Región de La Araucanía",
  },
  {
    value: "LR",
    label: "Región de Los Ríos",
  },
  {
    value: "LL",
    label: "Región de Los Lagos",
  },
  {
    value: "AI",
    label: "Región de Aysén del General Carlos Ibáñez del Campo",
  },
  {
    value: "MA",
    label: "Región de Magallanes y de la Antártica Chilena",
  },
  {
    value: "EX",
    label: "Extranjero",
  },
];
const status = [
  {
    value: "AG",
    label: "Agendado",
  },
  {
    value: "EJ",
    label: "Ejecucion",
  },
  {
    value: "FI",
    label: "Finalizado",
  },
];
const tipoProy = [
  {
    value: "Estudio Potencial Eolico",
    label: "Estudio Potencial Eolico",
  },
  {
    value: "Estudio Potencial Solar",
    label: "Estudio Potencial Solar",
  },
  {
    value: "Estudio Potencial Eolico y Solar",
    label: "Estudio Potencial Eolico y Solar",
  },
  {
    value: "Suministro",
    label: "Suministro",
  },
  {
    value: "Otro",
    label: "Otro",
  },
];
const tipo = [
  {
    value:'Eolico',
    label: 'Eolico'
  },
  {
    value:'Solar',
    label:'Solar'
  },
  {
    value:'Eolico y Solar',
    label:'Solar y Eolico'
  },
  {
    value:'Docencia',
    label:'Docencia'
  },
  {
    value:'Estudio',
    label:'Estudio'
  },
  {
    value:'Hidraulico',
    label:'Hidraulico'
  },
  {
    value:'Infraestructura',
    label:'Infraestructura'
  },
  {
    value:'Medio Ambiental',
    label:'Medio Ambiental'
  },
  {
    value:'Potencial Fotovoltaico',
    label:'Potencial Fotovoltaico'
  },
  {
    value:'Software',
    label:'Software'
  },
  {
    value:'Otro',
    label:'Otro'
  }

]

export default function AgregarProyecto() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [clientes, setCliente] = useState([]);
  const [regProy, setRegProy] = useState({
    titulo_proyecto: "",
    subtitulo_proyecto: "",
    num_proyecto_padre: "",
    id_cliente: "",
    fecha_ag: "",
    fecha_ej: null,
    fecha_fi: null,
    region: "",
    status: "",
    tipo: "",
    visible: false,
    proy_interes_1: "",
    proy_interes_2: "",
    proy_interes_3: "",
    proy_interes_4: "",
    descripcion: "",
  });

  const areRequiredFieldsFilled = () => {
    const requiredFields = [
      "titulo_proyecto",
      "subtitulo_proyecto",
      "id_cliente",
      "region",
      "status",
      "tipo",
    ];

    for (const field of requiredFields) {
      if (!regProy[field]) {
        return false; // Si falta algún campo requerido, devuelve false
      }
    }

    return true; // Si todos los campos requeridos están llenos, devuelve true
  };

  /*const axiosClient = axios.create({
    baseURL:'http://localhost:4000'
  })*/
  const axiosClient = axios.create({
    baseURL:'https://desamd.cl:4000'
  })
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "num_proyecto_padre") {
      // Verifica si el campo es num_proyecto_padre y si es vacío, asigna "000"
      const newValue = value === "" ? "000" : value;

      setRegProy((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      // Para otros campos, simplemente actualiza el valor
      setRegProy((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  console.log(regProy);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    const newValue = !regProy.visible;
    setRegProy((prevState) => ({
      ...prevState,
      visible: newValue,
    }));
  };

  const getClientes = async () => {
    await axiosClient
      .get("/api/clientes/getClientes")
      .then((response) => {
        console.log(response.data);
        setCliente(response.data);
      });
  };

  useEffect(() => {
    getClientes();
  }, []);

  const registroProyecto = async () => {
    console.log(regProy);
    await axiosClient
      .post("/api/proyectos/addProyecto", regProy)
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          mostrarAlertaRegistroCorrecto();
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error.request.status);

        if (error.request.status === 406) {
          console.log("Proyecto ya registrado");
          mostrarAlertaProyectoRegistrado();
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
          //mostrarAlertaUsuarioRegistrado()
        } else {
          mostrarServerError();
        }
      });
  };

  const mostrarAlertaRegistroCorrecto = () => {
    swal({
      title: "Registrado correctamente!",
      icon: "success",
    });
  };

  const mostrarAlertaProyectoRegistrado = () => {
    swal({
      title: "Proyecto ya registrado",
      text: "El id del proyecto ya estaba en nuestra base de datos",
      icon: "warning",
    });
  };

  const mostrarServerError = () => {
    swal({
      title: "SERVER ERROR",
      icon: "error",
    });
  };

  //const ids =  clientes.map(item => item.id)
  const ids = clientes.map((item) => item);
  return (
    <div>
      <Container sx={{ marginTop: 8 }} maxWidth="xl">
        <NavBarAdm />
        <h1 className={classes.text3}>
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: "bold" }}
            mb={6}
          >
            Agregar Proyecto
          </Typography>
        </h1>
        <div align="center">
          <div>
            <Box marginRight={"5%"} marginLeft={"5%"}>
              <Grid container sx={{ align: "center" }}>
                {/**Titulo proyecto */}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Titulo proyecto:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="titulo_proyecto"
                    required
                    select
                    id="outlined-required"
                    label="Titulo Proyecto"
                    fullWidth
                    margin="normal"
                    //error={!regProy.titulo_proyecto} // Si el campo está vacío, establece error en true
                    //helperText={!regProy.titulo_proyecto ? "Este campo es requerido" : ""}
                    onChange={handleChange}
                  >
                    {tipoProy.map((tipo) => (
                      <MenuItem key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/**Subtitulo proyecto*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Subtitulo Proyecto:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="subtitulo_proyecto"
                    required
                    id="outlined-required"
                    label="Subtitulo Proyecto"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  />
                </Grid>

                {/**Num proy pader*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Numero del proyecto padre:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="num_proyecto_padre"
                    id="outlined-required"
                    label="Numero proyecto padre"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  />
                </Grid>

                {/**ID cliente*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    ID Cliente:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="id_cliente"
                    required
                    id="outlined-required-select-currency"
                    select
                    label="ID Cliente"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  >
                    {ids.map((id) => (
                      <MenuItem key={id} value={id.id}>
                        {id.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/**region*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Region:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="region"
                    required
                    id="outlined-required-select-currency"
                    select
                    label="Region"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  >
                    {regiones.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/**status*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Status:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="status"
                    required
                    id="outlined-required-select-currency"
                    select
                    label="Status"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  >
                    {status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/**Tipo*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Tipo Proyecto:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="tipo"
                    required
                    select
                    id="outlined-required"
                    label="Tipo Proyecto"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  >
                    {tipo.map((tipo) => (
                      <MenuItem key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/**proy_interes_1*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Proyecto de interes:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="proy_interes_1"
                    id="outlined-required"
                    label="ID proyecto"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  />
                </Grid>

                {/**proy_interes_2*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Proyecto de interes:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="proy_interes_2"
                    id="outlined-required"
                    label="ID proyecto"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  />
                </Grid>

                {/**proy_interes_3*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Proyecto de interes:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="proy_interes_3"
                    id="outlined-required"
                    label="ID proyecto"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  />
                </Grid>

                {/**proy_interes_4*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Proyecto de interes:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="proy_interes_4"
                    id="outlined-required"
                    label="ID proyecto"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                  />
                </Grid>

                {/**Descripcion*/}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Descripcion:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item>
                  <TextField
                    name="descripcion"
                    id="outlined-require-multiline-static"
                    label="Descripcion"
                    fullWidth
                    multiline
                    maxRows={6}
                    margin="normal"
                    onChange={handleChange}
                  />
                </Grid>

                {/**Visible */}
                <Grid xs={12} sm={4} item style={{ textAlign: "left" }}>
                  <Typography variant="h5" mt={3} sx={{ fontWeight: "bold" }}>
                    Visible:
                  </Typography>
                </Grid>
                <Grid xs={12} sm={8} item style={{ textAlign: "center" }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ marginTop: "1.5rem" }}
                  >
                    <Typography>NO</Typography>
                    <Switch
                      defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                      checked={checked}
                      name="visible"
                      onChange={handleSwitchChange}
                    />
                    <Typography>SI</Typography>
                  </Stack>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <Box marginTop={5} marginLeft={"30%"} marginRight={"30%"}>
                    <Button
                      className={classes.button}
                      type="button"
                      marginTop={10}
                      size="large"
                      fullWidth
                      variant="contained"
                      onClick={() => registroProyecto()}
                      disabled={!areRequiredFieldsFilled()}
                    >
                      <Typography>Guardar</Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} item>
                  <Box marginTop={5} marginLeft={"30%"} marginRight={"30%"}>
                    <Button
                      className={classes.button}
                      type="button"
                      marginTop={10}
                      size="large"
                      fullWidth
                      variant="contained"
                      href="/proyectos"
                    >
                      <Typography>Atras</Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <br></br>
            <br></br>
          </div>
        </div>
      </Container>
    </div>
  );
}
