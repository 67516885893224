import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { Grid, makeStyles } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { useParams } from 'react-router-dom';
import { Container, Typography } from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react"
import { Pagination, Navigation, Autoplay, Mousewheel, Keyboard } from "swiper/modules"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import divider from '../../assets/divider_01.png' 

const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    gridContainer: {
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px',
        },
    },
    projectInfo: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
    },
    projectDetails: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
    },
    swiperContainer: {
        marginTop: '0px',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        },
    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    customFont: {
        fontFamily: "Arial, sans-serif",
    },
    
    footer: {
        backgroundColor: '#333', // Color de fondo del footer
        color: '#fff', // Color del texto del footer
        padding: theme.spacing(2), // Espaciado interno del footer
        textAlign: 'center', // Alineación del texto en el centro
        marginTop: 'auto', // Coloca el footer al final de la página
    },
}))

const regiones = {
    AP: 'Región de Arica y Parinacota',
    TA: 'Región de Tarapacá',
    AN: 'Región de Antofagasta',
    AT: 'Región de Atacama',
    CO: 'Región de Coquimbo',
    VA: 'Región de Valparaíso',
    RM: 'Región Metropolitana de Santiago',
    LI: "Región del Libertador General Bernardo O'Higgins",
    ML: 'Región del Maule',
    NB: 'Región de Ñuble',
    BI: 'Región del Biobío',
    AR: 'Región de La Araucanía',
    LR: 'Región de Los Ríos',
    LL: 'Región de Los Lagos',
    AI: 'Región de Aysén del General Carlos Ibáñez del Campo',
    MA: 'Región de Magallanes y de la Antártica Chilena'
}
const obtenerNombreRegion = (abreviatura) => {
    return regiones[abreviatura] || abreviatura;
};

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function DetalleExperiencia(){

    const classes = useStyles()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { projectId } = useParams();
    let [data, setData] = useState(null);
    const[proyectoSeleccionado, setProyectoSeleccionado] = useState({})
    const [imgData, setDataImage] = useState([])

    const cols = isMobile ? 1 : 2; // 1 columna en dispositivos móviles, 2 en otros dispositivos
    const rowHeight = isMobile ? 150 : 140; // Altura de fila ajustada para dispositivos móviles

    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/

    const axiosClient = axios.create({
        baseURL:'https://desamd.cl:4000'
    })

    function getColsByTitle(title) {
        // Lógica para definir las columnas según el título
        console.log('Titulo img:',title)
        if (title.includes('b') && !isMobile) {
            return 2;
        }else if (title.includes('b') && isMobile){
            return 1;
        }else if (title.includes('c')) {
            return 1;
        } else if (title.includes('d')) {
            return 1;
        }else{
          return 1; // Valor predeterminado
        }
    }
    
    const getProjectDetails = async()=>{
        
        await axiosClient.get('/api/proyectos/getproyectosID/'+projectId)
        .then(response=>{
            setData(response.data)
            setProyectoSeleccionado(response.data?response.data[0]:'')
        })
    }
    useEffect (() =>{
        getProjectDetails();

    },[])

    useEffect(() => {
        const fetchImages = async () => {
          try {
            const response = await axiosClient.get(`/api/proyectos/getimagenes/${projectId}`);
            const imageList = response.data;
            setDataImage(imageList); // Actualiza el estado con la lista de imágenes
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchImages(); // Llama a la función para obtener imágenes cuando el componente se monta
    }, [projectId]); // Asegúrate de que la función se llame cuando projectId cambie
    console.log(imgData)
   

    return(
        <div>
            <Container sx = {{marginTop:2}} maxWidth = 'xl'>
                <NavBar/>
            </Container>
            <Container sx = {{marginTop:8}} maxWidth = 'lg'>
                <ImageList 
                    sx={{ 
                        width: '100%', 
                        height: 'auto', 
                        justifyContent:'center',
                        gap: 4
                    }} 
                    variant='quilted'
                    cols={cols}
                    rowHeight={rowHeight}
                    
                >
                    {(imgData).map((i) =>(
                        <ImageListItem cols={getColsByTitle(i.title)} rows={3} >
                            <img 
                                src={i.dataUrl}
                                style={{ width:'100%',height:'100%', objectFit: 'cover'}}
                                loading='lazy'
                            />
                        </ImageListItem>
                    ))}
                   
                    
                </ImageList>
                <br></br>
                <Grid container style={{ marginTop: '40px' }}>
                    <Grid item xs={12} sm={5} style={{ marginTop: '60px' }}>
                        <Typography variant='h6' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }} >
                            PROYECTO 
                        </Typography>
                        <Typography variant='body1' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {proyectoSeleccionado.id_proyecto}
                        </Typography>
                        <br></br>
                        <Typography variant='h6' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            FECHA
                        </Typography>
                        <Typography variant='body1' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                        {proyectoSeleccionado && proyectoSeleccionado.fecha_fi
                        ? proyectoSeleccionado.fecha_fi.toString().slice(0, 10)
                        : "Fecha no disponible"}
                        </Typography>
                        <br></br>
                        <Typography variant='h6' color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}> 
                            ZONA
                        </Typography>
                        <Typography variant='body1' color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {obtenerNombreRegion(proyectoSeleccionado.region)}

                        </Typography>
                    
                    </Grid>
                    <Grid item xs={12} sm={7} style={{ marginTop: '50px' }} >
                        <Typography variant='h2' align='left' sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {proyectoSeleccionado.titulo_proyecto}
                        </Typography>
                        <br></br>
                        <Typography variant='h4' align='left' color={'grey'}  sx={{ fontFamily: 'Open Sans, sans-serif' }}> 
                            {proyectoSeleccionado.subtitulo_proyecto}
                        </Typography>
                        <br></br>
                        <Typography variant='body1' align='left' paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            {proyectoSeleccionado.descripcion}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
            <br></br>
            <br></br>
            <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}}>
                <img src = {divider} />
            </div>
            <footer className={classes.footer}>
                <Typography variant='body2' sx = {{fontFamily: 'Open Sans, sans-serif'}}>© 2024 Desamd. Todos los derechos reservados.</Typography>
            </footer>
        </div>
    );
}

