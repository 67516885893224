import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../components/NavBar";
import axios, { AxiosError } from "axios";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Button, Container, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import divider from '../../assets/divider_01.png' 

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000443",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const regiones = [
  {
    value: "AP",
    label: "Región de Arica y Parinacota",
  },
  {
    value: "TA",
    label: "Región de Tarapacá",
  },
  {
    value: "AN",
    label: "Región de Antofagasta",
  },
  {
    value: "AT",
    label: "Región de Atacama",
  },
  {
    value: "CO",
    label: "Región de Coquimbo",
  },
  {
    value: "VA",
    label: "Región de Valparaíso",
  },
  {
    value: "RM",
    label: "Región Metropolitana de Santiago",
  },
  {
    value: "LI",
    label: "Región del Libertador General Bernardo O'Higgins",
  },
  {
    value: "ML",
    label: "Región del Maule",
  },
  {
    value: "NB",
    label: "Región de Ñuble",
  },
  {
    value: "BI",
    label: "Región del Biobío",
  },
  {
    value: "AR",
    label: "Región de La Araucanía",
  },
  {
    value: "LR",
    label: "Región de Los Ríos",
  },
  {
    value: "LL",
    label: "Región de Los Lagos",
  },
  {
    value: "AI",
    label: "Región de Aysén del General Carlos Ibáñez del Campo",
  },
  {
    value: "MA",
    label: "Región de Magallanes y de la Antártica Chilena",
  },
  {
    value: "EX",
    label: "Extranjero",
  },
];
const obtenerNombreRegion = (abreviatura) => {
    return regiones[abreviatura] || abreviatura;
};

const useStyles = makeStyles(theme=>({
  
  footer: {
      backgroundColor: '#333', // Color de fondo del footer
      color: '#fff', // Color del texto del footer
      padding: theme.spacing(2), // Espaciado interno del footer
      textAlign: 'center', // Alineación del texto en el centro
      marginTop: 'auto', // Coloca el footer al final de la página
  },

  
  
}))
export default function ProyectosRegion() {
  const classes = useStyles()
  const { tipo, region } = useParams();
  const [proyectos, setProyectos] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useNavigate();

  /*const axiosClient = axios.create({
    baseURL:'http://localhost:4000'
  })*/

  const axiosClient = axios.create({
    baseURL:'https://desamd.cl:4000'
  })

  const getProjectDetails = async () => {
    try {
      const response = await axiosClient.get(
        `/api/proyectos/getfilteredprojects/${tipo}/${region}`
      );
      const formattedProjects = response.data.map((project) => ({
        ...project,
        fecha: moment(project.fecha).format("YYYY-MM-DD"),
      }));
      setProyectos(formattedProjects);
      console.log(formattedProjects);
    } catch (error) {
      console.error("Error al obtener los detalles del proyecto:", error);
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const handleProjectDetails = (projectId) => {
    // Cambia la ruta a la página de detalles del proyecto
    console.log(projectId)
    history(`/getdetalleexperiencia/${projectId}`);
  };
  return (
    <div>
      <Container sx={{ marginTop: 2 }} maxWidth="xl">
        <NavBar />
        <br></br>
        <br></br>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: isSmallScreen ? "20px" : "0",
            marginTop: "20px",
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontFamily: "Open Sans, sans-serif" }}
            textTransform={"uppercase"}
          >
            PROYECTOS {tipo} REGION {obtenerNombreRegion(region)}
          </Typography>
        </div>
        <br></br>
        <br></br>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  LUGAR PROYECTO
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  ID PROYECTO
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  CLIENTE
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  FECHA
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Open Sans, sans-serif",
                  }}
                >
                  ACCION
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proyectos.map((proyecto) => (
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    sx={{ fontFamily: "Open Sans, sans-serif" }}
                  >
                    {proyecto.lugar_proyecto}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontFamily: "Open Sans, sans-serif" }}
                  >
                    {proyecto.id_proyecto}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontFamily: "Open Sans, sans-serif" }}
                  >
                    {proyecto.nombre_cliente}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontFamily: "Open Sans, sans-serif" }}
                  >
                    {proyecto.fecha}
                  </StyledTableCell>
                  <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                    <Button onClick={() => handleProjectDetails(proyecto.id_proyecto)} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                      Ver Detalles
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}}>
          <img src = {divider} />
        </div>
        </Container>
        <footer className={classes.footer}>
            <Typography variant='body2' sx = {{fontFamily: 'Open Sans, sans-serif'}}>© 2024 Desamd. Todos los derechos reservados.</Typography>
        </footer>
      
    </div>
  );
}
